import { CataloguesService } from '../services/LandingV4'

const catalogos = new CataloguesService()
const phoneValidate = async (creditApplicationId, telefono) => {
    try {
        const response = await catalogos.postPhoneValidate(creditApplicationId, telefono)
        return response.data.isValid
    } catch (error) {
        console.log(error)
    }
}

export default phoneValidate